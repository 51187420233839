<template>
    <div>
        <SelectContractLabel />
        <BCard v-if="selectedContract">
            <BCardHeader>
                <BCardTitle class="text-center"> Solicitar demarcação de lote </BCardTitle>
            </BCardHeader>
            <BCardBody>
                <BCardText>
                    <p class="text-justify">
                        Solicito por meio deste a demarcação do imóvel acima citado. Estou(amos)
                        ciente(s) do prazo de até 10 (dez) dias úteis após a data de assinatura
                        presencial ou eletrônica deste requerimento, para demarcação, bem como o prazo
                        de 7 (sete) dias úteis após a demarcação para informar qualquer
                        irregularidade. Após tal prazo, será de minha responsabilidade o custo de
                        <b>R$ 500,00 (quinhentos reais)</b> para uma nova demarcação. Uma vez que o
                        imóvel estiver murado, a responsabilidade de limpeza bem como ônus relativos à
                        falta desta é exclusivamente minha. Estou ciente que devo apresentar o Alvará
                        de Construção, assim que ele for emitido, e, que a não apresentação do
                        documento, poderá acarretar a rescisão do contrato.
                    </p>
                    <p>
                        <b>É terminantemente proibido:</b>
                    </p>
                    <ul class="pl-1 pl-md-2">
                        <li>
                            Iniciar eventuais obras sem demarcação adequada do imóvel, sem presença de
                            caçamba para depósito de lixos e entulhos, embalagens de cimento, sobras de
                            concretos, sobras de madeiras e outros resíduos de obras;
                        </li>
                        <li>
                            Utilizar a calçada e/ou pavimento asfáltico para depósito de materiais e/ou
                            preparação de massas;
                        </li>
                        <li>
                            Utilizar terrenos vizinhos para descarga de materiais, entulhos e armação de
                            ferragens;
                        </li>
                        <li>
                            Permitir a lavagem de caminhões de concreto usinado e jogar sobras de
                            concreto nos terrenos vizinhos, asfalto ou em qualquer local do loteamento.
                        </li>
                        <li>
                            Tenho ciência de que ao não orientar meu mestre de obras e funcionários a
                            respeito das normas acima, serei responsabilizado por qualquer indenização
                            ou ônus em consequência a danos causados devido a desrespeito das normas
                            acima expostas.
                        </li>
                    </ul>
                </BCardText>
                <BFormCheckbox class="mt-4 text-justify" v-model="termoAceito">
                    <h5>Declaro que li e estou de acordo com as normas citadas neste documento.</h5>
                </BFormCheckbox>
            </BCardBody>
            <BCardFooter class="d-flex justify-content-start justify-content-md-end">
                <BButton variant="primary" class="col-12 col-md-4 mx-auto" @click="handleSubmitProcess()"
                    :disabled="!preencheRequisitos || !termoAceito">
                    <template v-if="!loading"> Continuar </template>
                    <template v-else>
                        <b-spinner small />
                    </template>
                </BButton>
            </BCardFooter>
        </BCard>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectContractLabel from '@/views/components/selectContractLabel.vue'
import { solicitarDemarcacao } from '@/services/process'
import store from '@/store'
import {
    BCard,
    BCardBody,
    BCardFooter,
    BButton,
    BCardText,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,
} from 'bootstrap-vue'
export default {
    props: {
        alvara: {
            type: String,
            required: false
        },
        extensao: {
            type: String,
            required: false
        },
    },
    name: 'StepFour',
    components: {
        SelectContractLabel,
        BCard,
        BCardBody,
        BCardFooter,
        BButton,
        BCardText,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        BSpinner,
    },
    data() {
        return {
            termoAceito: false,
            loading: false,
            errorMessage: null,
            hasErrors: false,
            processID: null,
        }
    },
    methods: {
        async handleSubmitProcess() {
            this.loading = true

            const { data, error } = await solicitarDemarcacao(this.selectedContract, this.alvara, this.extensao)
            
            if (data) {
                this.processID = data
                this.loading = false
                this.nextStep()
            } else {
                this.errorMessage = error
                this.hasErrors = true
                this.loading = false
                this.nextStep()
            }
        },

        nextStep() {
            if (this.preencheRequisitos) {
                if (this.termoAceito) {
                    this.$emit('nextStep')
                    this.$emit('submitProcess', {
                        processID: this.processID,
                        hasErrors: this.hasErrors,
                        errorMessage: this.errorMessage,
                    })
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Ops!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: 'Para solicitar é necessário aceitar os termos e condições.',
                        },
                    })
                }
            } else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Ops!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text:
                            'Para demarcar seu lote seu contrato deve preencher todos os requisitos necessários.',
                    },
                })
            }
        },
    },

    computed: {
        selectedContract() {
            return store.getters['contracts/getSelectedContract']
        },

        isAdimplente() {
            if (this.selectedContract.inadimplente === 'Não') {
                return true
            } else {
                return false
            }
        },
        isIptuAdimplente() {
            if (this.selectedContract.inadimplente_iptu === 'Não') {
                return true
            } else {
                return false
            }
        },
        percentualPago() {
            if (this.selectedContract.percentual_pago >= 9.9) {
                return true
            } else {
                return false
            }
        },

        liberadoConstrucao() {
            if (this.selectedContract.liberado_construcao === 'Sim') {
                return true
            } else {
                return false
            }
        },

        quantosDiasJaQuitado() {
            if (this.selectedContract.data_quitacao) {
                let dataQuitacao = new Date(
                    this.selectedContract.data_quitacao.split('-')[0],
                    this.selectedContract.data_quitacao.split('-')[1] - 1,
                    this.selectedContract.data_quitacao.split('-')[2]
                )

                let dataHoje = new Date()

                let diferenca = dataHoje.getTime() - dataQuitacao.getTime()

                let dias = Math.floor(diferenca / (1000 * 60 * 60 * 24))

                return dias
            } else {
                return 0
            }
        },

        maisDe60DiasNaoEscriturado() {
            if (!this.selectedContract.escriturado && this.quantosDiasJaQuitado > 60) {
                return true
            }
            return false
        },

        preencheRequisitos() {
            if (
                this.isAdimplente &&
                this.isIptuAdimplente &&
                this.percentualPago &&
                this.liberadoConstrucao &&
                !this.maisDe60DiasNaoEscriturado
            ) {
                return true
            } else {
                return true
            }
        },
    },
}
</script>
