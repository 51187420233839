<template>
	<div>
		<SelectContract />
		<SelectContractLabel />
		<BCard v-if="!processoEmAndamento && !loading && selectedContract">
			<BCardBody>
				<BCardText class="text-center text-md-left">
					<h4 class="mb-3">
						Requisitos necessários para a Demarcação de Lote:
					</h4>
					<div class="d-flex text-left my-1 align-items-center">
						<feather-icon
							:icon="returnIcon(isAdimplente).icon"
							size="19"
							:color="returnIcon(isAdimplente).color"
							class="mr-50"
						/>
						<h6 class="requisito">Estar adimplente.</h6>
					</div>

					<div class="d-flex text-left my-1 align-items-center">
						<feather-icon
							:icon="returnIcon(isIptuAdimplente).icon"
							size="19"
							:color="returnIcon(isIptuAdimplente).color"
							class="mr-50"
						/>
						<h6 class="requisito">Estar com Débitos Municipais em dia.</h6>
					</div>

					<div class="d-flex text-left my-1 align-items-center">
						<feather-icon
							:icon="returnIcon(percentualPago).icon"
							size="19"
							:color="returnIcon(percentualPago).color"
							class="mr-50"
						/>
						<h6 class="requisito">Pelo menos 10% do lote quitado.</h6>
					</div>

					<div class="d-flex text-left my-1 align-items-center">
						<feather-icon
							:icon="returnIcon(liberadoConstrucao).icon"
							size="19"
							:color="returnIcon(liberadoConstrucao).color"
							class="mr-50"
						/>
						<h6 class="requisito">Empreendimento liberado para construção.</h6>
					</div>

					<div class="d-flex text-left my-1 align-items-center">
						<feather-icon
							icon="MinusIcon"
							size="19"
							color="gray"
							class="mr-50"
						/>
						<h6 class="requisito">Anexar Alvará de Construção.</h6>
					</div>

					<div
						class="d-flex text-left my-1 align-items-center"
						v-if="maisDe60DiasNãoEscriturado"
					>
						<feather-icon
							:icon="returnIcon(!maisDe60DiasNãoEscriturado).icon"
							size="19"
							:color="returnIcon(!maisDe60DiasNãoEscriturado).color"
							class="mr-50"
						/>
						<h6 class="requisito">Lote Quitado sem Escritura.</h6>
					</div>

				</BCardText>

			</BCardBody>
			<BCardFooter class="d-flex justify-content-start justify-content-md-end">
				<BButton
					variant="primary"
					class="col-12 col-md-4 mx-auto"
					@click="nextStep()"
					:disabled="!preencheRequisitos"
				>
					Continuar
				</BButton>
			</BCardFooter>
		</BCard>
		<ProcessoExistente v-else-if="processoEmAndamento" />
		<div class="d-flex justify-content-center" v-else-if="loading">
			<BSpinner />
		</div>
	</div>
</template>

<style scoped>
.requisito {
	margin: 0;
	width: 90%;
}
</style>

<script>
import SelectContract from '@/views/components/selectContract.vue'
import SelectContractLabel from '@/views/components/selectContractLabel.vue'
import ProcessoExistente from '../components/ProcessoExistente.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import services from '@/services'
import store from '@/store'
import {
	BCard,
	BCardBody,
	BCardFooter,
	BButton,
	BCardText,
	BSpinner
} from 'bootstrap-vue'
export default {
	name: 'StepTwo',
	components: {
		SelectContract,
		SelectContractLabel,
		BCard,
		BCardBody,
		BCardFooter,
		BButton,
		BCardText,
		BSpinner,
		ProcessoExistente
	},

	data() {
		return {
			solicitacoes: [],
			loading: false,
		}
	},
	methods: {
		nextStep() {
			if (this.preencheRequisitos) {
				this.$emit('nextStep')
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Ops!',
						icon: 'XIcon',
						variant: 'danger',
						text:
							'Para demarcar seu lote seu contrato deve preencher todos os requisitos necessários.',
					},
				})
			}
		},

		returnIcon(isAdimplente) {
			if (isAdimplente) {
				return {
					icon: 'CheckIcon',
					color: 'green',
				}
			} else {
				return {
					icon: 'XIcon',
					color: 'red',
				}
			}
		},

		async getSolicitacoes() {
			this.loading = true
			this.solicitacoes = await services.process.getProcesses(
				this.$apolloProvider.defaultClient
			)
			setTimeout(() => {
				this.loading = false
			}, 300)
		},
	},

	computed: {
		selectedContract() {
			return store.getters['contracts/getSelectedContract']
		},

		processoEmAndamento() {
			if (this.solicitacoes.length > 0) {
				for (let solicitacao of this.solicitacoes) {
					if (
						solicitacao.id_processo == 'demarcacao_lote' &&
						solicitacao.is_finished != 1 &&
						solicitacao.is_canceled != 1
					) {
						return true
					}
				}
			}
			return false
		},

		isAdimplente() {
			if (this.selectedContract?.inadimplente === 'Não') {
				return true
			} else {
				return false
			}
		},
		isIptuAdimplente() {
			if (this.selectedContract?.inadimplente_iptu === 'Não') {
				return true
			} else {
				return false
			}
		},
		percentualPago() {
			if (this.selectedContract?.percentual_pago >= 9.9) {
				return true
			} else {
				return false
			}
		},

		liberadoConstrucao() {
			if (this.selectedContract?.liberado_construcao === 'Sim') {
				return true
			} else {
				return false
			}
		},

		loteEscriturado() {
			if (this.selectedContract?.escriturado === 'Sim') {
				return true
			} else {
				return false
			}
		},

		quantosDiasJaQuitado() {
			if (this.selectedContract?.data_quitacao) {
				let dataQuitacao = new Date(
					this.selectedContract.data_quitacao.split('-')[0],
					this.selectedContract.data_quitacao.split('-')[1] - 1,
					this.selectedContract.data_quitacao.split('-')[2]
				)

				let dataHoje = new Date()

				let diferenca = dataHoje.getTime() - dataQuitacao.getTime()

				let dias = Math.floor(diferenca / (1000 * 60 * 60 * 24))

				return dias
			} else {
				return 0
			}
		},

		maisDe60DiasNãoEscriturado() {
			if (!this.loteEscriturado && this.quantosDiasJaQuitado > 60) {
				return true
			}
			return false
		},

		preencheRequisitos() {
			if (
				this.isAdimplente &&
				this.isIptuAdimplente &&
				this.percentualPago &&
				this.liberadoConstrucao &&
				!this.maisDe60DiasNãoEscriturado &&
				!this.processoEmAndamento
			) {
				return true
			} else {
				return false
			}
		},
	},

	watch: {
		selectedContract: {
			handler: function() {
				this.selectedContract ? this.getSolicitacoes() : null
			},
		},
	},
}
</script>
